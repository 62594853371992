import React, { useEffect, useState } from "react";

const Error = () => {
  const [message, setMessage] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const errorMessage = params.get("message");
    setMessage(errorMessage);
  }, []);
  return (
    <div className="flex items-center justify-center h-screen text-primary">
      <p>{message}</p>
    </div>
  );
};

export default Error;
