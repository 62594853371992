import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
const Index = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const email = params.get("email");
  const [keywords, setKeywords] = useState([]);
  const [spreadsheetData, setSpreadsheetData] = useState([]);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState(null);
  const [sheetData, setSheetData] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState(null);

  async function getKeywords(id) {
    try {
      const response = await fetch(
        `https://keywords.searchactions.com:3007/getKeywords?id=${id}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setKeywords(JSON.parse(data[0].keywords));
    } catch (error) {
      window.alert(error.message);
    }
  }
  async function getSpreadsheetData(email) {
    try {
      const response = await fetch(
        `https://keywords.searchactions.com:3007/listSheets?email=${email}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("SpreadsheetData ", data);
      setSpreadsheetData(data.data);
      setSelectedSpreadsheet(data.data[0]);
      getGoogleSheetData(data.data[0].id, email);
    } catch (error) {
      console.error(error);
    }
  }
  async function getGoogleSheetData(sheetId, email) {
    try {
      const response = await fetch(
        `https://keywords.searchactions.com:3007/googleSheets?id=${sheetId}&email=${email}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("GooglesheetData ", data);
      setSheetData(data.data);
      setSelectedSheet(data.data[0]);
      return data.data[0];
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    getKeywords(id);
    getSpreadsheetData(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const downloadExcel = () => {
    const json = keywords.map((item) => {
      return {
        URL: item.page,
        "Keyword 1": item?.queries[0]?.query || "",
        "Keyword 2": item?.queries[1]?.query || "",
        "Keyword 3": item?.queries[2]?.query || "",
        Combined: [
          item?.queries[0]?.query,
          item?.queries[1]?.query,
          item?.queries[2]?.query,
        ]
          .filter(Boolean)
          .join(", "),
      };
    });
    const ws = XLSX.utils.json_to_sheet(json);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Keywords");
    XLSX.writeFile(wb, "keywords.xlsx");
  };
  useEffect(() => {
    if (selectedSpreadsheet) {
      getGoogleSheetData(selectedSpreadsheet.id, email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpreadsheet]);
  const updateSheet = async (sheetId, email, data) => {
    try {
      const response = await fetch(
        `https://keywords.searchactions.com:3007/updateSheet?id=${sheetId}&email=${email}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      await response.json();
    } catch (error) {
      window.alert(error.message);
    }
  };
  return (
    <div>
      <div className="overflow-x-auto">
        <div className="flex flex-wrap w-full gap-10 my-10 ml-10">
          <details className="dropdown">
            <summary className="m-1 btn">{selectedSpreadsheet?.name}</summary>
            <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-200 rounded-box ">
              {spreadsheetData?.map((item, index) => (
                <li key={item.name + index}>
                  <p
                    onClick={() => {
                      setSelectedSpreadsheet(item);
                    }}
                  >
                    {item.name}
                  </p>
                </li>
              ))}
            </ul>
          </details>
          <details className="dropdown">
            <summary className="m-1 btn">
              {selectedSheet || "Loading.."}
            </summary>
            <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-200 rounded-box ">
              {sheetData?.map((item, index) => (
                <li key={index + item}>
                  <p
                    onClick={() => {
                      setSelectedSheet(item);
                    }}
                  >
                    {item}
                  </p>
                </li>
              ))}
            </ul>
          </details>
          <button
            className="btn btn-primary"
            onClick={() => {
              updateSheet(selectedSheet.id, email, keywords);
            }}
          >
            Add to Sheet
          </button>
          <button className="btn btn-primary" onClick={downloadExcel}>
            Download Excel file
          </button>
        </div>
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              <th></th>
              <th>URL</th>
              <th>Keyword 1</th>
              <th>Keyword 2</th>
              <th>Keyword 3</th>
              <th>Combined</th>
            </tr>
          </thead>
          <tbody>
            {keywords?.map((item, index) => (
              <tr key={index}>
                <th>{index + 1}</th>
                <td className="min-w-[180px]">
                  <a href={item.page} className="break-all link link-primary">
                    {item.page}
                  </a>
                </td>
                <td>{item?.queries[0]?.query || ""}</td>
                <td>{item?.queries[1]?.query || ""}</td>
                <td>{item?.queries[2]?.query || ""}</td>
                <td>
                  {[
                    item?.queries[0]?.query,
                    item?.queries[1]?.query,
                    item?.queries[2]?.query,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Index;
