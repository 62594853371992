import React, { useRef } from "react";

const Homepage = () => {
  const inputRef = useRef();

  const handleClick = () => {
    fetch("https://keywords.searchactions.com:3007/auth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: inputRef.current.value,
      }),
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (!response.ok) {
          if (contentType && contentType.includes("application/json")) {
            return response.json().then((error) => {
              throw new Error(error.message);
            });
          } else {
            throw new Error("Server error");
          }
        }
        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else {
          throw new Error("Invalid content type");
        }
      })
      .then((data) => (window.location.href = data.url))
      .catch((err) => window.alert(err.message));
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-5">
      <p>GSC Extracting Keywords</p>

      <div>
        <input
          type="text"
          className="mr-5 input input-bordered"
          placeholder="Website URL"
          ref={inputRef}
        />

        <button className="btn btn-primary" onClick={handleClick}>
          Start
        </button>
      </div>
    </div>
  );
};

export default Homepage;
